import React from "react"
import Seo from "./../../components/seo"

import { Box } from "@mui/material"
import Page from "../../components/page"

import { StaticImage } from "gatsby-plugin-image"


const AboutDialogi = (props) => {

  return (
    <>
      <Seo title="O Dialogih" />
      <Box sx = { (theme) => ({
        bgcolor: theme.palette.white
      })}>
        <Page className={'content'}>
            <h1>O Dialogih</h1>
            <p>Dialogi so ena najstarejših slovenskih kulturnih revij, saj v Mariboru izhajajo že od leta 1965 naprej. Dialoge so soustvarjale številne generacije mariborskih izobražencev in literatov, povečini so bili predvsem literarna revija z lokalnim poudarkom, v posameznih obdobjih pa so se usmerili tudi k širšim kulturnim tematikam.</p>
            <p>Od leta 1994 naprej, ko je njihov izdajatelj postala Založba Aristej, odgovorna urednica pa Emica Antončič, so se pričeli razvijati kot splošno kulturna revija, dobili podnaslov Revija za kulturo in družbo in se usmerili v vseslovenski in mednarodni prostor. Uredništvo objavlja in vzpodbuja kritično misel s področja različnih umetnosti, humanistike in družboslovja, upoštevajoč posebej civilno družbo, neodvisno kulturo in angažirane intelektualce.</p>
            <p>Zahtevnejši bralec bo v Dialogih lahko prebral poglobljene intervjuje z domačimi in tujimi osebnostmi ter ocene domačih in tujih umetniških dogodkov, tudi takšnih, ki so v množičnih medijih  pogosto spregledani. Posebnost revije, ki vzbuja največ odzivov, so tematski sklopi, posvečeni posameznim aktualnim temam. V skladu s tradicijo slovenskih kulturnih revij Dialogi objavljajo tudi izvirno in prevodno leposlovje.</p>
            <p>Aprila 1999 so se Dialogi kot prvi partner iz Slovenije pridružili Eurozinu, mednarodnemu medmrežju, ki od leta 1989 združuje vodilne evropske kulturne revije in s tem omogoča bogat in svoboden evropski dialog ter javni prostor.  Na spletni strani Eurozina najdete posamezne članke iz Dialogov v angleških prevodih ali izvirnikih.</p>

            
            <Box mt={8} mb={8}>
              <a href="https://www.eurozine.com/journals/dialogi/" rel="noreferrer" target={'_blank'}>
                <StaticImage 
                  src="../../images/eurozine-logo.svg" 
                  alt="Eurozine Logo"
                  placeholder="none"
                />
              </a>
            </Box>

            <p>Izhajanje Dialogov finančno omogočata Javna agencija za knjigo RS in Mestna občina Maribor.</p>

            <h2>Uredništvo</h2>
            <Box sx = { (theme) => ({
              display: {
                md: 'flex',
              },
              gap: theme.spacing(10)
            })}>
              <Box>
                <p>
                  <strong>Emica Antončič </strong>(odgovorna urednica)<br/>
                  <strong>Igor Bašin</strong> (glasba)<br/>
                  <strong>Primož Jesenko</strong> (scenske umetnosti)<br/>
                  <strong>Petra Kolmančič</strong> (literatura)<br/>
                  <strong>Meta Kordiš</strong> (neodvisna kultura)<br/>
                  <strong>Nataša Kovšca</strong> (vizualne umetnosti)<br/>
                  <strong>Matic Majcen</strong> (film)<br/>
                  <strong>Ciril Oberstar</strong> (družboslovje)<br/>
                  <strong>Boris Vezjak</strong> (kritična misel)<br/>
                  <strong>Jasmina Založnik</strong> (feministični pristopi)
                </p>
              </Box>

              <Box>
                <p>Lektorica za slovenščino:<br/>
                <strong>Emica Antončič</strong></p>
                <p>Lektorica in prevajalka v angleščino:<br/>
                <strong>Jean McCollister</strong></p>
                <p>Oblikovanje:<br/>
                <strong>Matjaž Wenzel / MILIMETER</strong></p>
              </Box>
            </Box>

            <h2>Kontakt</h2>
            <p><strong>Naslov uredništva:</strong><br/>
                Dialogi, Založba Aristej,<br/>
                p.p. 115, 2116 Maribor
            </p>
            <p>
              <strong>T)</strong> 02 250 21 93, 02 250 21 94<br/>
              <strong>F)</strong> 02 252 18 97<br/>
              <strong>E)</strong> <a href="mailto:info@aristej.si">info@aristej.si</a></p>
           
          </Page>
      </Box>
    </>
  )
}

export default AboutDialogi